import { default as offlineFTVCwV4diJMeta } from "/usr/src/app/pages/offline.vue?macro=true";
import { default as IndexZVdwBbS82nMeta } from "/usr/src/app/pages/Redmonkey/Index.vue?macro=true";
import { default as RenderhlhBwHAGxdMeta } from "/usr/src/app/pages/Redmonkey/Render.vue?macro=true";
export default [
  {
    name: "offline___ua",
    path: "/offline",
    component: () => import("/usr/src/app/pages/offline.vue")
  },
  {
    name: "Redmonkey-Index___ua",
    path: "/Redmonkey/Index",
    component: () => import("/usr/src/app/pages/Redmonkey/Index.vue")
  },
  {
    name: "Redmonkey-Render___ua",
    path: "/Redmonkey/Render",
    component: () => import("/usr/src/app/pages/Redmonkey/Render.vue")
  }
]