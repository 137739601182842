import { defineNuxtPlugin } from '#app';
import { defaultConfig } from '~/integration/wordpress/defaultConfig';
import { getLocaleSettings } from '~/integration/wordpress/helpers/getLocaleSettings';

// Assume the structure of moduleOptions and additionalProperties for typing
interface ModuleOptions {
  [key: string]: unknown;
}

interface AdditionalProperties {
  [key: string]: unknown;
}

export const mapConfigToSetupObject = ({
                                         app,
                                         moduleOptions,
                                         additionalProperties = {},
                                       }: {
  nuxtApp: ReturnType<typeof defineNuxtPlugin>,
  moduleOptions: ModuleOptions,
  additionalProperties: AdditionalProperties,
}) => {
  // Assuming getLocaleSettings is also updated for Nuxt 3
  const localeSettings = getLocaleSettings(app, moduleOptions, additionalProperties);

  return {
    ...defaultConfig,
    ...moduleOptions,
    ...additionalProperties,
    ...localeSettings,
  };
};
