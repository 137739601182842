import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
    routes: (_routes) => [
        {
            name: 'home',
            path: '/',
            component: () => import('~/pages/Redmonkey/Index.vue').then(r => r.default || r)
        },
        {
            name: 'offline',
            path: '/offline',
            component: () => import('~/pages/offline.vue').then(r => r.default || r)
        },
        {
            name: 'render',
            path: '/render/component',
            component: () => import('~/pages/Redmonkey/Render.vue').then(r => r.default || r)
        },
        {
            name: 'page',
            path: '/:slug+',
            component: () => import('~/pages/Redmonkey/Index.vue').then(r => r.default || r)
        },
    ],
    scrollBehaviorType: 'smooth',
    scrollBehavior: async (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition
        }

        if (to.hash) {
           return {
               el: to.hash,
               behavior: 'smooth'
           }
        }

        return {
            top: 0
        }
    }
}
