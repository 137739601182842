export const defaultConfig = {
  cookies: {
    // currencyCookieName: 'vsf-currency',
    // countryCookieName: 'vsf-country',
    localeCookieName: 'vsf-locale',
    // cartCookieName: 'vsf-cart',
    // customerCookieName: 'vsf-customer',
    websiteCookieName: 'vsf-store',
    // messageCookieName: 'vsf-message',
    PHPSESSID: 'PHPSESSID',
    // updatedCookieName: 'vsf-updated',
  },
  locale: undefined as undefined,
  // country: undefined as undefined,
  // currency: undefined as undefined,
} as const;
