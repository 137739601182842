import {mapConfigToSetupObject} from '~/integration/wordpress/helpers/mapConfigToSetupObject';
import {defaultConfig} from '~/integration/wordpress/defaultConfig';
import {createAddIntegrationToCtx} from "~/integration/wordpress/integrationPlugin/context";
import {createProxiedApi, getIntegrationConfig} from "~/integration/wordpress/integrationPlugin/_proxyUtils";
import axios from "axios";
import https from 'https';

const moduleOptions = JSON.parse('{"api":"https://stage.backend.rmonkey.org/graphql","apiDependencies":[{"frontend":"stage.frontend.rmonkey.org","backend":"https://stage.backend.rmonkey.org/graphql"}],"cookies":{"localeCookieName":"vwf-locale","websiteCookieName":"vwf-website","PHPSESSID":"PHPSESSID"},"cookiesDefaultOpts":{"httpOnly":false,"secure":false,"sameSite":"Strict","path":"/"},"defaultWebsite":"ua","wpBaseUrl":"https://stage.backend.rmonkey.org","wpApiEndpoint":"https://stage.backend.rmonkey.org/graphql"}');

export default defineNuxtPlugin(nuxtApp => {
    const tag = 'wordpress';

    const getCookieName = (property: keyof typeof defaultConfig['cookies']) : string => moduleOptions.cookies?.[property] ?? defaultConfig.cookies[property];
    const cookieOpts = {
        [defaultConfig.cookies.localeCookieName]: { ...moduleOptions.cookiesDefaultOpts },
        [defaultConfig.cookies.websiteCookieName]: { ...moduleOptions.cookiesDefaultOpts },
        [defaultConfig.cookies.PHPSESSID]: { ...moduleOptions.cookiesDefaultOpts },
    };

    const getCookieOpt = (cookieName?: string) => cookieOpts[cookieName] ?? {};

    const cookieNames = {
        locale: getCookieName('localeCookieName'),
        website: getCookieName('websiteCookieName'),
        PHPSESSID: getCookieName('PHPSESSID'),
    };

    const {$cookies, $config, $i18n} = nuxtApp;

    const createCookieOperationsInstance = <TValue = string>(cookies) => (cookieName: string) => ({
        get: (opts?) => cookies.get(cookieName, opts),
        set: (value: TValue, opts?) => cookies.set(cookieName, value, { ...getCookieOpt(cookieName), ...opts }),
        remove: (opts?) => cookies.remove(cookieName, opts),
    });

    const createCookieOperations = createCookieOperationsInstance($cookies);

    const {
        get: getSessionId,
        set: setSessionId,
        remove: removeSessionId,
    } = createCookieOperations(cookieNames.PHPSESSID);

    const {
        get: getWebsite,
        set: setWebsite,
        remove: removeWebsite,
    } = createCookieOperations(cookieNames.website);

    const {
        get: getLocale,
        set: setLocale,
        remove: removeLocale,
    } = createCookieOperations(cookieNames.locale);


    const settings = mapConfigToSetupObject({
        moduleOptions,
        app: nuxtApp,
        additionalProperties: {
            state: {
                getLocale,
                setLocale,
                removeLocale,

                getSessionId,
                setSessionId,
                removeSessionId,

                getWebsite,
                setWebsite,
                removeWebsite
            },
        },
    });

    const injectInContext = createAddIntegrationToCtx({ tag: tag, nuxtCtx: nuxtApp, provide: nuxtApp.provide });
    const config = getIntegrationConfig(nuxtApp, settings);

    const { middlewareUrl, ssrMiddlewareUrl } = $config.public;

    if (middlewareUrl || ssrMiddlewareUrl) {
        config.axios = {
            ...config.axios,
            baseURL: process.server ? ssrMiddlewareUrl || middlewareUrl : middlewareUrl
        }

        const domain = $i18n.localeProperties?.value?.domain;

        if(domain && process.client) {
            config.axios = {
                ...config.axios,
                baseURL: config.axios.baseURL.split('://')[0] + '://' + domain + '/api/'
            }
        }
    }

    if(process.server) {
        config.axios.httpsAgent = new https.Agent({
            rejectUnauthorized: process.env.NODE_TLS_REJECT_UNAUTHORIZED !== '0'
        });
    }

    const client = axios.create(config.axios);

    const api = createProxiedApi({
        givenApi: settings.proxyApi || {}, client, tag, nuxtCtx: nuxtApp,
    });

    injectInContext({ api, client, config });
})