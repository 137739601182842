import { defaultConfig } from '../defaultConfig';

export const getLocaleSettings = (nuxtApp, moduleOptions: Record<string, unknown>, additionalProperties: any) => {
    const localeSettings = moduleOptions.cookies
        ? {
            // currency: additionalProperties.state.getCurrency(),
            locale: additionalProperties.state.getLocale(),
            // country: additionalProperties.state.getCountry(),
        }
        : {};

    // Assuming i18n setup is similar to Nuxt 2, which may not be directly applicable
    // You would need to adapt based on how i18n is configured in your Nuxt 3 app
    const i18nLocale = nuxtApp.$i18n?.locale ?? undefined;

    return {
        // currency: localeSettings.currency || moduleOptions.currency || defaultConfig.currency || undefined,
        locale: localeSettings.locale || i18nLocale || moduleOptions.locale || defaultConfig.locale || undefined,
        // country: localeSettings.country || moduleOptions.country || defaultConfig.country || undefined,
    };
};
