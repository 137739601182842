import {merge} from 'lodash-es';
import {Logger} from '/helpers/logger';

export type ApiClientMethod = (...args) => Promise<any>;

export const createProxiedApi = ({
                                     givenApi, client, tag, nuxtCtx,
                                 }) => new Proxy(givenApi, {
    get: (target, prop, receiver) => {
        const functionName = String(prop);
        if (Reflect.has(target, functionName)) {
            return Reflect.get(target, prop, receiver);
        }

        // eslint-disable-next-line @typescript-eslint/require-await
        // return async (...args) => client
        //   .post(`/${tag}/${functionName}`, args)
        //   .then((r) => r.data)
        //   .catch((err) => {
        //     Logger.debug(err);
        //     console.log(err);
        //
        //     return {};
        //   });

        return async (...args) => $fetch(`/api/${tag}/${functionName}`, {
            method: "POST",
            body: args
        }).then((r) => r)
            .catch((err) => {
                Logger.debug(err);
                console.log(err);
                return {};
            })
    },
});

export const getCookies = (context) => context?.req?.headers?.cookie ?? '';

export const getIntegrationConfig = (context, configuration: any) => {
    const cookie = getCookies(context);
    return merge({
        axios: {
            headers: {
                ...(cookie ? {cookie} : {})
            },
            withCredentials: true,
        },
    }, configuration);
};
